// App.js

import React, { useEffect, useRef } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import gsap from "gsap";
import { Helmet } from "react-helmet";
import "./index.css";
import FourthSectionSlider from "./components/slider"; // Проверьте путь при необходимости
import Quiz from "./components/quiz"; // Проверьте путь при необходимости

// Компонент для структурированных данных (JSON-LD)
const OrganizationSchema = () => {
    const schema = {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Vinogradoff",
        "url": "https://a-vin.ru", // Замените на URL вашего сайта
        "logo": "https://a-vin.ru/assets/logo.png", // Замените на реальный логотип
        "contactPoint": [{
            "@type": "ContactPoint",
            "telephone": "+7 995 304-98-85",
            "contactType": "customer service"
        }],
        "founder": {
            "@type": "Person",
            "name": "Алексей Михайлович Виноградов"
        },
        "foundingDate": "2024"
    };

    return (
        <script type="application/ld+json">
            {JSON.stringify(schema)}
        </script>
    );
};

// Страница политики конфиденциальности
const Privacy = () => {
    return (
        <>
            <Helmet>
                <title>Политика конфиденциальности | Vinogradoff</title>
                <meta name="description" content="Политика конфиденциальности компании Vinogradoff. Узнайте, как мы собираем, используем и защищаем персональные данные пользователей." />
                <link rel="canonical" href="https://yourwebsite.com/privacy" />
            </Helmet>
            <main className="privacy-page p-8 max-w-4xl mx-auto">
                <h1 className="text-3xl font-bold mb-4">Политика конфиденциальности</h1>
                <p className="mb-4">
                    Здесь будет размещен текст политики конфиденциальности. Опишите, как ваша компания собирает, использует и защищает персональные данные пользователей.
                </p>
                <p>
                    Вы можете добавить разделы, например: "Какие данные собираются", "Как используется информация", "Права пользователей", "Контактная информация" и т.д.
                </p>
                <div className="mt-6">
                    <Link to="/" className="text-blue-500 hover:underline text-sm">
                        ← Назад на главную
                    </Link>
                </div>
            </main>
        </>
    );
};

// Главная страница
function Home() {
    const sectionRef = useRef(null);

    useEffect(() => {
        const blocks = sectionRef.current.querySelectorAll(".fade-in-inner");
        gsap.fromTo(
            blocks,
            { opacity: 0, y: 20 },
            {
                opacity: 1,
                y: 0,
                duration: 1,
                stagger: 0.2,
                ease: "power2.out",
                clearProps: "transform",
            }
        );
    }, []);

    return (
        <>
            <Helmet>
                <title>Главная страница | Vinogradoff</title>
                <meta name="description"
                      content="Мы создаем вдохновляющий интернет: от landing page до самых сложных проектов. Узнайте о наших услугах по разработке сайтов, чат-ботов и дизайну."/>
                <link rel="canonical" href="https://a-vin.ru/"/>
                <meta property="og:image" content="https://a-vin.ru/assets/logo.png"/>
                {/* Добавление ключевых слов */}
                <meta
                    name="keywords"
                    content="разработка сайтов, чат-боты, дизайн, digital, веб-разработка, UX/UI, landing page, интернет-проекты, программирование, оптимизация, мобильные приложения, креатив, реклама, продвижение, SEO оптимизация, маркетинг, дизайн логотипа, фирменный стиль, адаптивность, интернет-маркетинг, сквозная аналитика, CRM, электронная коммерция, интернет магазины, разработка на React, Node.js, front-end, back-end, JavaScript, HTML, CSS, SASS, кроссбраузерность, пользовательский интерфейс, пользовательский опыт, инновации, веб дизайн, графический дизайн, разработка на заказ, консалтинговые услуги, брендирование, создание сайтов, быстрая загрузка, авторский дизайн, уникальный дизайн, интерактивные элементы, контент-менеджмент, цифровые технологии, бизнес решения, технологические решения"
                />
            </Helmet>
            <OrganizationSchema/>
            <main>
                {/* Первая секция */}
                <section
                    ref={sectionRef}
                    className="bg-white p-4 md:mt-16 md:p-0 font-helvetica flex items-center justify-center"
                >
                    <div className="max-w-4xl py-8 mx-auto grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-6">
                        {/* Главный блок */}
                        <article
                            className="md:col-span-2 rounded-2xl p-6 md:p-8 flex flex-row justify-between items-center hover-effect transition-transform">
                            <div className="fade-in-inner">
                                <h1 className="text-2xl md:text-3xl font-medium leading-tight mb-3 md:mb-4 text-[#4356F6]">
                                    Мы создаем вдохновляющий интернет
                                </h1>
                                <p className="text-xs opacity-70">
                                    От простых landing page
                                    <br />
                                    до самых сложных проектов.
                                </p>
                            </div>
                            <div className="ml-4 flex-shrink-0">
                                <img
                                    src="/assets/rocket.png"
                                    alt="Иллюстрация ракеты"
                                    className="w-32 h-32 object-contain"
                                    loading="lazy"
                                />
                            </div>
                        </article>

                        {/* Веб-сайты */}
                        <a href="#websites" className="hover-effect bg-[#303336] text-white rounded-2xl flex flex-row justify-between items-center md:row-span-2 md:flex-col md:justify-between md:items-start p-6 md:p-0 transition-transform transition-shadow duration-300 hover:scale-[1.02] hover:shadow-[0_5px_10px_rgba(48,51,54,0.25)]">
                                <div className="p-6">
                                    <div className="fade-in-inner">
                                        <h3 className="text-xl md:text-2xl font-medium mb-2 md:mb-3">
                                            Веб-сайты
                                        </h3>
                                        <p className="text-xs opacity-70">
                                            Разрабатываем сайты, где продуманный функционал гармонично сочетается с
                                            элегантным дизайном. Наши решения работают на результат и приносят пользу
                                            вашим клиентам.
                                        </p>
                                    </div>
                                </div>
                                <img
                                    src="/assets/web-dev.png"
                                    alt="Разработка веб-сайта"
                                    className="object-contain w-1/3 md:w-full"
                                    loading="lazy"
                                />
                        </a>

                        {/* Чат-боты */}
                        <a href="#chatbots" className="hover-effect bg-[#F9FAFC] rounded-2xl p-5 flex flex-row justify-between items-center md:p-6 md:flex-col md:items-start transition-transform transition-shadow duration-300 hover:scale-[1.02] hover:shadow-[0_5px_10px_rgba(209,213,219,0.25)]">
                                <div className="flex-1 md:mb-4">
                                    <div className="fade-in-inner">
                                        <h3 className="text-lg md:text-xl font-medium mb-2 text-gray-800">
                                            Чат-боты
                                        </h3>
                                        <p className="text-xs opacity-70 text-gray-500">
                                            Улучшаем клиентский сервис с помощью умных чат-ботов
                                        </p>
                                    </div>
                                </div>
                                <img
                                    src="/assets/chat-bot.png"
                                    alt="Чат-бот"
                                    className="w-16 md:w-20 object-contain"
                                    loading="lazy"
                                />
                        </a>

                        {/* Дизайн */}
                        <article className="hover-effect bg-[#F9FAFC] rounded-2xl p-5 flex flex-row justify-between items-center md:p-6 md:flex-col md:items-start transition-transform transition-shadow duration-300 hover:scale-[1.02] hover:shadow-[0_5px_10px_rgba(209,213,219,0.25)]">
                            <div className="flex-1 md:mb-4">
                                <div className="fade-in-inner">
                                    <h3 className="text-lg md:text-xl font-medium mb-2 text-gray-800">
                                        Дизайн
                                    </h3>
                                    <p className="text-xs opacity-70 text-gray-500">
                                        Создаём яркий дизайн для брендов, постеров и креативов
                                    </p>
                                </div>
                            </div>
                            <img
                                src="/assets/design.png"
                                alt="Пример дизайна"
                                className="w-16 md:w-20 object-contain"
                                loading="lazy"
                            />
                        </article>
                    </div>
                </section>

                {/* Вторая секция (Веб-сайты) */}
                <section id="websites" className="bg-white py-10 md:py-16 font-helvetica max-w-4xl mx-auto">
                    <div className="max-w-5xl mx-auto px-4">
                        <div className="mb-10 fade-in-inner">
                            <h2 className="text-3xl md:text-2xl font-medium leading-tight text-[#4356F6]">
                                Веб-сайты
                            </h2>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                            {/* Левая колонка */}
                            <article className="flex flex-col justify-center space-y-4">
                                <div className="fade-in-inner">
                                    <h3 className="text-base md:text-lg font-medium mb-1 text-gray-800">
                                        Работа с вашими сайтами
                                    </h3>
                                    <p className="text-xs text-gray-500 leading-relaxed">
                                        Мы внедряем рабочий резерв (бэкап) уже существующих проектов, чтобы вы могли не переживать о безопасности и удобстве для пользователей.
                                    </p>
                                </div>

                                <div className="fade-in-inner">
                                    <h3 className="text-base md:text-lg font-medium mb-1 text-gray-800">
                                        Прозрачность в работе
                                    </h3>
                                    <p className="text-xs text-gray-500 leading-relaxed">
                                        Детальные отчёты, понятные сметы и открытые коммуникации – залог доверия между нами и вами.
                                    </p>
                                </div>

                                <div className="fade-in-inner">
                                    <h3 className="text-base md:text-lg font-medium mb-1 text-gray-800">
                                        Сложные проекты
                                    </h3>
                                    <p className="text-xs text-gray-500 leading-relaxed">
                                        Берём в работу даже самые сложные задачи и проекты, ведь любим вызовы, а результат всегда впечатляет.
                                    </p>
                                </div>
                            </article>

                            {/* Правая колонка */}
                            <article className="space-y-4">
                                <div className="fade-in-inner bg-[#F9FAFC] py-5 px-8 rounded-2xl border border-[#4356F680] border-[0.5px]">
                                    <h3 className="text-base md:text-lg font-medium mb-1 text-gray-800">
                                        CMS-поддержка
                                    </h3>
                                    <p className="text-xs text-gray-500 leading-relaxed">
                                        Мы поможем настроить систему управления и возьмём работу с пользовательским опытом и контентом на обслуживании.
                                    </p>
                                </div>

                                <div className="fade-in-inner bg-[#F9FAFC] py-5 px-8 rounded-2xl border border-[#4356F680] border-[0.5px]">
                                    <h3 className="text-base md:text-lg font-medium mb-1 text-gray-800">
                                        SEO оптимизация
                                    </h3>
                                    <p className="text-xs text-gray-500 leading-relaxed">
                                        Грамотно выстраиваем оптимизацию кода, улучшаем видимость вашего сайта в поисковиках и способствуем притоку целевой аудитории.
                                    </p>
                                </div>

                                <div className="fade-in-inner bg-[#F9FAFC] py-5 px-8 rounded-2xl border border-[#4356F680] border-[0.5px]">
                                    <h3 className="text-base md:text-lg font-medium mb-1 text-gray-800">
                                        Прозрачность в работе
                                    </h3>
                                    <p className="text-xs text-gray-500 leading-relaxed">
                                        Проекты ведутся на открытых платформах, благодаря которым вы всегда в курсе и контролируете процесс.
                                    </p>
                                </div>

                                <div className="fade-in-inner bg-[#F9FAFC] py-5 px-8 rounded-2xl border border-[#4356F680] border-[0.5px]">
                                    <h3 className="text-base md:text-lg font-medium mb-1 text-gray-800">
                                        Быстрый старт
                                    </h3>
                                    <p className="text-sm text-gray-500 leading-relaxed">
                                        Запустим ваш сайт или проект в сжатые сроки, не жертвуя качеством.
                                    </p>
                                </div>
                            </article>
                        </div>
                    </div>
                </section>

                {/* Третья секция (Чат-боты) */}
                <section id="chatbots" className="bg-white py-10 md:py-16 font-helvetica max-w-4xl mx-auto">
                    <div className="max-w-5xl mx-auto px-4">
                        <div className="mb-10 fade-in-inner">
                            <h2 className="text-3xl md:text-2xl font-medium leading-tight text-[#4356F6]">
                                Чат-боты
                            </h2>
                        </div>

                        <div className="grid md:grid-flow-row auto-cols-fr gap-8">
                            {/* Основные пункты */}
                            <article className="flex flex-col md:flex-row gap-8 justify-center">
                                <div className="fade-in-inner">
                                    <h3 className="text-base md:text-lg font-medium mb-1 text-gray-800">
                                        Круглосуточная поддержка
                                    </h3>
                                    <p className="text-xs text-gray-500 leading-relaxed">
                                        Чат-боты доступны 24/7, обеспечивая мгновенную реакцию на вопросы пользователей и освобождая ваши ресурсы от постоянного круглосуточного дежурства.
                                    </p>
                                </div>

                                <div className="fade-in-inner">
                                    <h3 className="text-base md:text-lg font-medium mb-1 text-gray-800">
                                        Экономия затрат
                                    </h3>
                                    <p className="text-xs text-gray-500 leading-relaxed">
                                        Использование чат-ботов снижает расходы на обслуживание и оплату труда: автоматизация рутинных процессов требует минимального участия человека.
                                    </p>
                                </div>

                                <div className="fade-in-inner">
                                    <h3 className="text-base md:text-lg font-medium mb-1 text-gray-800">
                                        Сбор и анализ данных
                                    </h3>
                                    <p className="text-xs text-gray-500 leading-relaxed">
                                        Чат-боты автоматически собирают информацию о поведении пользователей, что позволяет формировать базу для аналитики и выстраивать эффективные маркетинговые стратегии.
                                    </p>
                                </div>
                            </article>

                            {/* Карточки */}
                            <article className="flex flex-col md:flex-row justify-center gap-4">
                                <div className="fade-in-inner bg-[#F9FAFC] py-5 px-8 rounded-2xl border border-[#4356F680] border-[0.5px]">
                                    <h3 className="text-base md:text-lg font-medium mb-1 text-gray-800">
                                        Снижение нагрузки на службу поддержки
                                    </h3>
                                    <p className="text-xs text-gray-500 leading-relaxed">
                                        Автоматизация типовых запросов позволяет вашим специалистам сосредоточиться на решении более сложных задач, повышая общую эффективность службы поддержки.
                                    </p>
                                </div>

                                <div className="fade-in-inner bg-[#F9FAFC] py-5 px-8 rounded-2xl border border-[#4356F680] border-[0.5px]">
                                    <h3 className="text-base md:text-lg font-medium mb-1 text-gray-800">
                                        Персонализация общения
                                    </h3>
                                    <p className="text-xs text-gray-500 leading-relaxed">
                                        Чат-боты адаптируются к индивидуальным запросам клиентов, запоминая предпочтения и предоставляя ответы, точно отвечающие потребностям пользователя.
                                    </p>
                                </div>
                            </article>
                        </div>
                    </div>
                </section>

                {/* Четвёртая секция (Наши работы) */}
                <section id="our-works" className="bg-white font-helvetica max-w-4xl mx-auto">
                    <FourthSectionSlider />
                </section>

                {/* Пятая секция (Квиз) */}
                <section className="bg-white font-helvetica max-w-4xl mx-auto">
                    <Quiz />
                </section>
            </main>
        </>
    );
}

// Футер (семантический элемент <footer>)
function Footer() {
    const currentYear = new Date().getFullYear();
    return (
        <footer className="pb-8 px-4">
            <div className="max-w-4xl mx-auto flex flex-col md:flex-row justify-between items-start md:items-center gap-4 bg-[#303336] text-white p-8 rounded-2xl">
                {/* Информация о компании */}
                <section>
                    <h2 className="text-3xl font-semibold mb-2">Vinogradoff</h2>
                    <p className="text-xs">ИП Виноградов Алексей Михайлович {currentYear}</p>
                    <p className="text-xs">ИНН: 3023023033</p>
                    <p className="text-xs">ОРГН: 3030330000111</p>
                </section>

                {/* Контакты */}
                <section>
                    <a
                        href="mailto:alex@exmain.ru"
                        className="text-2xl font-bold hover:underline block mb-1"
                    >
                        alex@exmain.ru
                    </a>
                    <a
                        href="tel:+79953049885"
                        className="text-2xl font-bold hover:underline"
                    >
                        +7 995 304-98-85
                    </a>
                </section>

                {/* Навигация */}
                <nav className="flex flex-col text-xs">
                    <a href="#websites" className="hover:underline mb-1">
                        Веб-сайты
                    </a>
                    <a href="#chatbots" className="hover:underline mb-1">
                        Чат-боты
                    </a>
                    <a href="#our-works" className="hover:underline mb-1">
                        Наши работы
                    </a>
                    <Link to="/privacy" className="hover:underline">
                        Политика конфиденциальности
                    </Link>
                </nav>
            </div>
        </footer>
    );
}

// Основной компонент приложения
export default function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/privacy" element={<Privacy />} />
            </Routes>
            <Footer />
        </Router>
    );
}
