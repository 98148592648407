// quiz.js
import React, { useState } from "react";
import { PatternFormat } from "react-number-format";

// Стилизация (при необходимости можно добавить/подкорректировать классы под ваш Tailwind/PostCSS)
const buttonClass = "px-4 py-2 rounded-2xl transition-colors hover:opacity-90";
const activeButtonClass = "bg-[#4356F6] text-white"; // Используется только для кнопок «Далее» / «Назад» и отправки
const inactiveButtonClass = "bg-gray-300 text-gray-700"; // Ответные кнопки всегда будут с этим стилем

const optionsWhatToDevelop = [
    "Веб-сайт",
    "Чат-бот",
    "Дизайн",
    "Другое",
];

export default function Quiz() {
    // шаг квиза
    const [step, setStep] = useState(0);
    // режим работы: null, "quiz", "callMe"
    const [mode, setMode] = useState(null);
    // состояния успешной отправки и ошибки
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState("");

    // URL API сервера для отправки данных (замените на актуальный)
    const API_URL = "/api/submit";

    // Данные формы
    const [formData, setFormData] = useState({
        whatToDevelop: "",          // Вопрос 1. Что нужно разработать? (один вариант из списка)
        existingProject: null,      // Вопрос 2: нужно ли доработать существующий проект?
        haveDesign: "",             // Вопрос 3: есть ли готовый дизайн/схема?
        targetAudience: "",         // Вопрос 4: целевая аудитория
        forecastUsers: "",          // Вопрос 5: количество пользователей в день
        competitors: "",            // Вопрос 6: конкуренты
        needSupport: null,          // Вопрос 7: нужна ли поддержка
        needInstallation: null,     // Вопрос 8: нужна ли помощь с установкой (бесплатно)
        name: "",                   // ФИО
        contactMethod: "phone",     // способ связи: "phone" или "telegram"
        phone: "",                  // номер телефона
        telegram: "",               // username в Telegram (с символом @)
    });

    // Функция для обновления данных формы
    const handleChange = (field, value) => {
        setFormData((prev) => ({ ...prev, [field]: value }));
    };

    // Переходы по шагам квиза
    const nextStep = () => setStep((prev) => prev + 1);
    const prevStep = () => setStep((prev) => prev - 1);

    // Обработчик для кнопки "Просто позвоните мне"
    const handleCallMe = () => {
        setMode("callMe");
        setStep(0);
    };

    // Функция валидации обязательных полей
    const validateForm = () => {
        // Общие обязательные поля для обоих режимов
        if (!formData.name.trim()) {
            return "Пожалуйста, введите ваше имя.";
        }
        if (formData.contactMethod === "phone") {
            if (!formData.phone.trim() || formData.phone.indexOf("_") !== -1) {
                return "Пожалуйста, введите корректный номер телефона.";
            }
        } else if (formData.contactMethod === "telegram") {
            if (!formData.telegram.trim()) {
                return "Пожалуйста, введите ваш username в Telegram.";
            }
        }
        // Если режим квиза, проверяем обязательное поле в вопросе 1
        if (mode === "quiz" && !formData.whatToDevelop) {
            return "Пожалуйста, выберите, что нужно разработать.";
        }
        return null;
    };

    // Обработка отправки формы
    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");
        // Валидация формы
        const validationError = validateForm();
        if (validationError) {
            setError(validationError);
            return;
        }
        console.log("Отправляем данные:", formData);

        // Отправляем данные на сервер
        try {
            const response = await fetch(API_URL, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(formData),
            });
            if (!response.ok) {
                throw new Error(`Ошибка сервера: ${response.status}`);
            }
            setSubmitted(true);
            // Сброс состояния формы после успешной отправки
            setMode(null);
            setStep(0);
            setFormData({
                whatToDevelop: "",
                existingProject: null,
                haveDesign: "",
                targetAudience: "",
                forecastUsers: "",
                competitors: "",
                needSupport: null,
                needInstallation: null,
                name: "",
                contactMethod: "phone",
                phone: "",
                telegram: "",
            });
        } catch (err) {
            setError("При отправке данных произошла ошибка. Попробуйте ещё раз.");
            console.error(err);
        }
    };

    // Рендер поля для ФИО и способа контакта
    const renderNameAndContact = () => {
        return (
            <div className="space-y-4 ">
                {/* Имя */}
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        Ваше имя
                    </label>
                    <input
                        type="text"
                        value={formData.name}
                        onChange={(e) => handleChange("name", e.target.value)}
                        placeholder="Иван"
                        className="w-full p-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#4356F6]"
                    />
                </div>

                {/* Выбор способа связи */}
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        Предпочитаемый способ связи
                    </label>
                    <div className="flex items-center space-x-4">
                        <label className="flex items-center space-x-1">
                            <input
                                type="radio"
                                name="contactMethod"
                                value="phone"
                                checked={formData.contactMethod === "phone"}
                                onChange={() => handleChange("contactMethod", "phone")}
                            />
                            <span className="text-sm">Телефон</span>
                        </label>
                        <label className="flex items-center space-x-1">
                            <input
                                type="radio"
                                name="contactMethod"
                                value="telegram"
                                checked={formData.contactMethod === "telegram"}
                                onChange={() => handleChange("contactMethod", "telegram")}
                            />
                            <span className="text-sm">Telegram</span>
                        </label>
                    </div>
                </div>

                {/* Ввод номера телефона или Telegram */}
                {formData.contactMethod === "phone" ? (
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Номер телефона (Россия)
                        </label>
                        <PatternFormat
                            format="+7 (###) ###-##-##"
                            mask="_"
                            value={formData.phone}
                            onValueChange={(values) => {
                                const { formattedValue } = values;
                                handleChange("phone", formattedValue);
                            }}
                            placeholder="+7 (___) ___-__-__"
                            className="w-full p-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#4356F6]"
                        />
                    </div>
                ) : (
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Ваш @username в Telegram
                        </label>
                        <input
                            type="text"
                            placeholder="@yourusername"
                            value={formData.telegram}
                            onChange={(e) => handleChange("telegram", e.target.value)}
                            className="w-full p-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#4356F6]"
                        />
                    </div>
                )}
                <p className="text-xs">
                    Нажимая кнопку "Отправить" вы соглашаетесь с{" "}
                    <a className="text-[#4356F6]" href="/privacy">
                        политикой обработки персональных данных.
                    </a>
                </p>
            </div>
        );
    };

    // Контент для режима "Просто позвоните мне"
    const renderCallMeContent = () => {
        return (
            <>
                <h3 className="text-xl md:text-2xl font-medium mb-4 text-gray-800">
                    Оставьте ваши данные
                </h3>
                {renderNameAndContact()}
            </>
        );
    };

    // Рендер вопросов квиза по шагам
    const renderQuizSteps = () => {
        switch (step) {
            // Вопрос 1: Что нужно разработать? (варианты через чекбоксы, имитирующие radio)
            case 0:
                return (
                    <div className="space-y-4">
                        <h3 className="text-xl md:text-2xl font-medium text-gray-800">
                            1. Что нужно разработать?
                        </h3>
                        <p className="text-sm text-gray-500">Выберите один вариант:</p>
                        <div className="flex flex-col space-y-2">
                            {optionsWhatToDevelop.map((option) => (
                                <label
                                    key={option}
                                    className="flex items-center p-2 border rounded-md cursor-pointer transition-colors border-gray-300 bg-white"
                                >
                                    <input
                                        type="checkbox"
                                        checked={formData.whatToDevelop === option}
                                        onChange={() =>
                                            handleChange(
                                                "whatToDevelop",
                                                formData.whatToDevelop === option ? "" : option
                                            )
                                        }
                                        className="mr-2"
                                    />
                                    <span className="text-sm">{option}</span>
                                </label>
                            ))}
                        </div>
                    </div>
                );
            // Вопрос 2: Уже есть проект, нуждается ли доработка?
            case 1:
                return (
                    <div className="space-y-4">
                        <h3 className="text-xl md:text-2xl font-medium text-gray-800">
                            2. Нужно доработать проект (если он уже существует)?
                        </h3>
                        <div className="flex space-x-4">
                            <button
                                type="button"
                                onClick={() => {
                                    handleChange("existingProject", true);
                                    nextStep();
                                }}
                                className={`${buttonClass} ${inactiveButtonClass}`}
                            >
                                Да
                            </button>
                            <button
                                type="button"
                                onClick={() => {
                                    handleChange("existingProject", false);
                                    nextStep();
                                }}
                                className={`${buttonClass} ${inactiveButtonClass}`}
                            >
                                Нет
                            </button>
                        </div>
                    </div>
                );
            // Если проект уже существует, сразу запрашиваем контактные данные (переход сразу на шаг контактов)
            case 2:
                if (formData.existingProject === true) {
                    return (
                        <div className="space-y-4">
                            <h3 className="text-xl md:text-2xl font-medium text-gray-800">
                                Расскажите о себе
                            </h3>
                            {renderNameAndContact()}
                        </div>
                    );
                } else {
                    // Если проекта нет, переходим к следующему вопросу
                    return (
                        <div className="space-y-4">
                            <h3 className="text-xl md:text-2xl font-medium text-gray-800">
                                3. Имеется ли у вас готовый дизайн или схема работы продукта?
                            </h3>
                            <textarea
                                placeholder="Опишите, если есть..."
                                rows={4}
                                className="w-full p-2 rounded-md border border-gray-300"
                                value={formData.haveDesign}
                                onChange={(e) => handleChange("haveDesign", e.target.value)}
                            />
                        </div>
                    );
                }
            // Вопрос 4
            case 3:
                return (
                    <div className="space-y-4">
                        <h3 className="text-xl md:text-2xl font-medium text-gray-800">
                            4. Какая ваша целевая аудитория?
                        </h3>
                        <textarea
                            placeholder="Возраст, интересы, сфера деятельности..."
                            rows={4}
                            className="w-full p-2 rounded-md border border-gray-300"
                            value={formData.targetAudience}
                            onChange={(e) => handleChange("targetAudience", e.target.value)}
                        />
                    </div>
                );
            // Вопрос 5
            case 4:
                return (
                    <div className="space-y-4">
                        <h3 className="text-xl md:text-2xl font-medium text-gray-800">
                            5. Какое количество пользователей в день будет пользоваться вашим продуктом?
                        </h3>
                        <input
                            type="number"
                            placeholder="Например, 1000"
                            className="w-full p-2 rounded-md border border-gray-300"
                            value={formData.forecastUsers}
                            onChange={(e) => handleChange("forecastUsers", e.target.value)}
                        />
                    </div>
                );
            // Вопрос 6
            case 5:
                return (
                    <div className="space-y-4">
                        <h3 className="text-xl md:text-2xl font-medium text-gray-800">
                            6. Какие у вас главные конкуренты и есть ли они вообще?
                        </h3>
                        <textarea
                            placeholder="Если конкурентов нет, укажите это"
                            rows={4}
                            className="w-full p-2 rounded-md border border-gray-300"
                            value={formData.competitors}
                            onChange={(e) => handleChange("competitors", e.target.value)}
                        />
                    </div>
                );
            // Вопрос 7
            case 6:
                return (
                    <div className="space-y-4">
                        <h3 className="text-xl md:text-2xl font-medium text-gray-800">
                            7. Нуждаетесь ли вы в последующей поддержке продукта?
                        </h3>
                        <div className="flex space-x-4">
                            <button
                                type="button"
                                onClick={() => {
                                    handleChange("needSupport", true);
                                    nextStep();
                                }}
                                className={`${buttonClass} ${inactiveButtonClass}`}
                            >
                                Да
                            </button>
                            <button
                                type="button"
                                onClick={() => {
                                    handleChange("needSupport", false);
                                    nextStep();
                                }}
                                className={`${buttonClass} ${inactiveButtonClass}`}
                            >
                                Нет
                            </button>
                        </div>
                    </div>
                );
            // Вопрос 8
            case 7:
                return (
                    <div className="space-y-4">
                        <h3 className="text-xl md:text-2xl font-medium text-gray-800">
                            8. Нуждаетесь ли вы в помощи с установкой? (Это бесплатно)
                        </h3>
                        <div className="flex space-x-4">
                            <button
                                type="button"
                                onClick={() => {
                                    handleChange("needInstallation", true);
                                    nextStep();
                                }}
                                className={`${buttonClass} ${inactiveButtonClass}`}
                            >
                                Да
                            </button>
                            <button
                                type="button"
                                onClick={() => {
                                    handleChange("needInstallation", false);
                                    nextStep();
                                }}
                                className={`${buttonClass} ${inactiveButtonClass}`}
                            >
                                Нет
                            </button>
                        </div>
                    </div>
                );
            // Вопрос 9 (финальный – контактные данные)
            case 8:
                return (
                    <div className="space-y-4">
                        <h3 className="text-xl md:text-2xl font-medium text-gray-800">
                            9. Контакты для связи
                        </h3>
                        {renderNameAndContact()}
                    </div>
                );
            default:
                return <div>Шаг не найден</div>;
        }
    };

    // Рендер кнопок управления (Назад / Далее / Отправить)
    const renderButtons = () => {
        if (mode === "callMe") {
            return (
                <div className="mt-6 flex space-x-4">
                    <button
                        type="submit"
                        className={`${buttonClass} ${activeButtonClass}`}
                    >
                        Отправить
                    </button>
                </div>
            );
        }
        // Определяем, на последнем ли шаге (если проект существует, то шаг с контактами у нас — 2)
        const isLastStep =
            formData.existingProject === true ? step === 2 : step === 8;
        return (
            <div className="mt-6 flex space-x-4">
                {step > 0 && (
                    <button
                        type="button"
                        onClick={prevStep}
                        className={`${buttonClass} ${inactiveButtonClass}`}
                    >
                        Назад
                    </button>
                )}
                {isLastStep ? (
                    <button
                        type="submit"
                        className={`${buttonClass} ${activeButtonClass}`}
                    >
                        Отправить
                    </button>
                ) : (
                    <button
                        type="button"
                        onClick={nextStep}
                        className={`${buttonClass} ${activeButtonClass}`}
                    >
                        Далее
                    </button>
                )}
            </div>
        );
    };

    return (
        <section className="bg-white py-10 md:py-16 font-helvetica max-w-4xl mx-auto flex md:flex-row flex-col gap-4 px-4">
            <div className="flex flex-col md:w-1/3 bg-[#4356F6] rounded-2xl justify-between h-full">
                <div className="p-6">
                    <h2 className="text-2xl md:text-3xl font-medium leading-tight text-white">
                        Заполните бриф
                    </h2>
                    <p className="text-sm opacity-75 text-white text-light">
                        И получите бесплатную консультацию
                    </p>
                </div>
                <div className="mt-4">
                    <img src="/assets/phone.png" alt="Картинка" className="md:w-full object-contain" />
                </div>
            </div>

            <div className="flex flex-col md:w-2/3">
                <div className="p-6 rounded-2xl bg-[#F9FAFC] border border-[#4356F680] border-[0.5px]">
                    {/* Выбор режима квиза */}
                    {mode === null && (
                        <div className="flex flex-col md:flex-row items-start md:items-center space-y-4 md:space-y-0 md:space-x-4">
                            <button
                                type="button"
                                onClick={() => {
                                    setMode("quiz");
                                    setStep(0);
                                }}
                                className={`${buttonClass} ${activeButtonClass}`}
                            >
                                Заполнить бриф
                            </button>
                            <button
                                type="button"
                                onClick={handleCallMe}
                                className={`${buttonClass} bg-white text-[#4356F6] border border-[#4356F6] hover:bg-[#4356F6] hover:text-white transition-colors`}
                            >
                                Просто позвоните мне
                            </button>
                        </div>
                    )}

                    {/* Рендер формы */}
                    {mode === "quiz" && (
                        <form onSubmit={handleSubmit} className="mt-6 space-y-6">
                            {renderQuizSteps()}
                            {renderButtons()}
                        </form>
                    )}

                    {mode === "callMe" && (
                        <form onSubmit={handleSubmit} className="mt-6 space-y-6">
                            {renderCallMeContent()}
                            {renderButtons()}
                        </form>
                    )}

                    {/* Сообщение об ошибке */}
                    {error && (
                        <div className="mt-6 p-4 border border-red-400 bg-red-100 text-red-800 rounded-md">
                            {error}
                        </div>
                    )}

                    {/* Сообщение об успешной отправке */}
                    {submitted && (
                        <div className="mt-6 p-4 border border-green-400 bg-green-100 text-green-800 rounded-md">
                            Ваши данные успешно отправлены!
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
}
