import React, { useState, useRef, useEffect } from "react";
import gsap from "gsap";

// Пример массива слайдов (замените пути на актуальные для вашего проекта)
const slidesData = [
  {
    id: 1,
    title: "Долговик",
    description: "Логотип для Долговик",
    image: "/assets/project-1.png",
  },
  {
    id: 2,
    title: "Долговик",
    description:
      "Landing page с приемом заявок на сайте. На чистом Tailwind + PHP",
    image: "/assets/project-2.png",
  },
  {
    id: 3,
    title: "Farrfor",
    description:
      "Landing page по готовому брендбуку компании на Tilda Publishing",
    image: "/assets/project-9.png",
  },
  {
    id: 4,
    title: "Анастасия Ефимова",
    description:
      "Landing page по дизайну созданному с нуля, использовался чистый bootstrap",
    image: "/assets/project-3.png",
  },
  {
    id: 5,
    title: "Онлайн-курсы Анастасии Ефимовой",
    description:
      "Самописная LMS на php с приемом оплаты и разграничением доступов, выдачей и продлением",
    image: "/assets/project-4.png",
  },
  {
    id: 6,
    title: "Онлайн-курсы SkillFab",
    description:
      "Самописная LMS на Flask. Полностью динамичная и независимая платформа для обучения.",
    image: "/assets/project-5.png",
  },
  {
    id: 7,
    title: "Обслуживание веб-сайта T + A",
    description:
      "Еженедельное обновление информации, а так же доработка стилей и функционала веб-сайта.",
    image: "/assets/project-7.png",
  },
  {
    id: 8,
    title: "Обслуживание веб-сайта Аси Барышевой",
    description: "Доработка/исправление ошибок внутри кода веб-сайта.",
    image: "/assets/project-6.png",
  },
  {
    id: 9,
    title: "Обслуживание веб-сайта STOPFIRE",
    description: "Доработка/исправление ошибок внутри кода веб-сайта.",
    image: "/assets/project-10.png",
  },
  {
    id: 10,
    title: "Обновление Itcube37",
    description:
      "Перевод на собственную CRM систему, а так же полный редизайн стилей.",
    image: "/assets/project-8.png",
  },
];

export default function FourthSectionSlider() {
  const [currentIndex, setCurrentIndex] = useState(0);
  // Состояние, которое сигнализирует, что все изображения предзагружены
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);
  const slideRef = useRef(null);

  // Предзагрузка всех изображений при монтировании компонента
  useEffect(() => {
    const loadImages = slidesData.map((slide) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = slide.image;
        img.onload = resolve;
        img.onerror = resolve;
      });
    });

    Promise.all(loadImages).then(() => {
      setAllImagesLoaded(true);
    });
  }, []);

  // GSAP-анимация при переключении слайдов
  useEffect(() => {
    if (slideRef.current) {
      gsap.fromTo(
        slideRef.current,
        { autoAlpha: 0, y: 30 },
        {
          autoAlpha: 1,
          y: 0,
          duration: 0.5,
          ease: "power2.out",
          clearProps: "transform",
        }
      );
    }
  }, [currentIndex]);

  // Функция для переключения на предыдущий слайд
  const handlePrev = () => {
    setCurrentIndex((prev) =>
      prev === 0 ? slidesData.length - 1 : prev - 1
    );
  };

  // Функция для переключения на следующий слайд
  const handleNext = () => {
    setCurrentIndex((prev) =>
      prev === slidesData.length - 1 ? 0 : prev + 1
    );
  };

  // Пока изображения не загрузятся, можно показать общий лоадер для слайдера
  if (!allImagesLoaded) {
    return (
      <section className="bg-white py-10 md:py-16 font-helvetica max-w-4xl mx-auto flex items-center justify-center min-h-[300px]">
        <span>Загрузка...</span>
      </section>
    );
  }

  return (
    <section className="bg-white py-10 md:py-16 font-helvetica max-w-4xl mx-auto">
      {/* Заголовок секции */}
      <div className="mb-10 px-4">
        <h2 className="text-3xl md:text-2xl font-medium leading-tight text-[#4356F6]">
          Наши работы
        </h2>
      </div>

      {/* Слайдер */}
      <div className="relative px-4 flex flex-col items-center">
        {/* Контейнер слайда */}
        <div className="w-full flex items-center justify-center">
          <div
            ref={slideRef}
            className="w-full bg-[#F9FAFC] rounded-2xl flex flex-col md:flex-row items-center transition-transform"
          >
            {/* Картинка слайда */}
            <div className="w-full flex justify-center mb-4 md:mb-0">
              <img
                key={slidesData[currentIndex].id} // гарантирует новый элемент при смене слайда
                src={slidesData[currentIndex].image}
                alt={slidesData[currentIndex].title}
                className="object-cover w-full rounded-lg"
              />
            </div>
            {/* Дополнительный блок для размещения текста или других элементов */}
          </div>
        </div>

        {/* Индикаторы (точки) */}
        <div className="mt-4 flex space-x-2 mb-4">
          {slidesData.map((slide, index) => (
            <div
              key={slide.id}
              className={`w-3 h-3 rounded-full transition-colors ${
                index === currentIndex ? "bg-[#4356F6]" : "bg-gray-300"
              }`}
            ></div>
          ))}
        </div>

        {/* Описание слайда и кнопки навигации */}
        <div className="text-left w-full">
          <div className="flex flex-row justify-between">
            <div className="flex flex-col">
              <h3 className="text-xl font-medium text-gray-800">
                {slidesData[currentIndex].title}
              </h3>
              <p className="text-sm text-gray-600">
                {slidesData[currentIndex].description}
              </p>
            </div>

            <div className="flex flex-row gap-2">
              <button
                onClick={handlePrev}
                className="w-10 h-10 rounded-full flex items-center justify-center bg-[#ECEEF2] hover:bg-[#4356F6] group transition-colors"
              >
                {/* Стрелка влево (SVG) */}
                <svg
                  className="w-4 h-4 text-gray-600 group-hover:text-white"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </button>

              <button
                onClick={handleNext}
                className="w-10 h-10 rounded-full flex items-center justify-center bg-[#ECEEF2] hover:bg-[#4356F6] group transition-colors"
              >
                {/* Стрелка вправо (SVG) */}
                <svg
                  className="w-4 h-4 text-gray-600 group-hover:text-white"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
